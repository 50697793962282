import * as React from 'react';
import styled from 'styled-components/macro';
import { addDays, format, min } from 'date-fns';
import useThunkReducer from 'react-hook-thunk-reducer';

import NumberInput from '../NumberInput';
import DateInput from '../DateInput';
import SelectInput from '../SelectInput';

import Submit from './Submit';
import Reset from './Reset';
import useSearchActions from './useSearchActions';
import { SearchState } from './SearchState';
import { Options } from './SearchOptions';
import searchReducer from './searchReducer';
import ArrivalLikeDeparture from './ArrivalLikeDeparture';
import BabyInfo from './BabyInfo';
import { trackEvent, trackEvents, trackGA4ClickEvent, trackGA4FormInteraction, trackingValueList } from '../../tealium';

type Props = {
  resetState: SearchState;
  initialState: SearchState;
  options: Options;
};

export const SearchWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px;

  label:first-child {
    margin-bottom: 30px;
  }
`;

export const Clear = styled.div`
  clear: both;
`;

const Search: React.FC<Props> = (
  {
    resetState,
    initialState,
    options,
    children,
  }) => {
  const [state, dispatch] = useThunkReducer(searchReducer, initialState);
  const actions = useSearchActions(dispatch, options);

  return (
    <>
      <h1>Kreuzfahrtsuche</h1>
      <SearchWrap>
        <InputWrap>
          <NumberInput
            label="Erwachsene"
            defaultValue={initialState.adults}
            value={state.adults}
            min={options.cruise.passengers.minAdults}
            max={options.cruise.passengers.maxAdults}
            onChange={(adults) => {
              actions.setAdults(adults);
            }}
            onEnter={() => {
              trackGA4FormInteraction('kreuzfahrtsuche', 'click.adults');
            }}
            onIncrement={() => {
              trackGA4FormInteraction('kreuzfahrtsuche', 'click.adults.increment');
            }}
            onDecrement={() => {
              trackGA4FormInteraction('kreuzfahrtsuche', 'click.adults.decrement');
            }}
          /> <NumberInput
          label="Kinder"
          defaultValue={initialState.children}
          value={state.children}
          min={options.cruise.passengers.minChildren}
          max={options.cruise.passengers.maxChildren}
          onChange={(children) => {
            actions.setChildren(children);
          }}
          onEnter={() => {
            trackGA4FormInteraction('kreuzfahrtsuche', 'click.children');
          }}
          onIncrement={() => {
            trackGA4FormInteraction('kreuzfahrtsuche', 'click.children.increment');
          }}
          onDecrement={() => {
            trackGA4FormInteraction('kreuzfahrtsuche', 'click.children.decrement');
          }}
        /> <BabyInfo /></InputWrap>
        <InputWrap>
          <DateInput
            label="Frühester Reisebeginn"
            value={state.firstCheckInDate}
            onChange={actions.setFirstCheckInDate}
            defaultValue={initialState.firstCheckInDate}
            minDate={options.periods.firstCheckInDate}
            maxDate={options.periods.lastCheckOutDate}
            onEnter={() => {
              trackGA4FormInteraction('kreuzfahrtsuche', 'click.first-check-in-date');
            }}
          /> {(() => {
          const maxDate =
            options.cruise.dateRange === Infinity
              ? options.periods.lastCheckOutDate
              : min([
                addDays(state.firstCheckInDate, options.cruise.dateRange),
                options.periods.lastCheckOutDate,
              ]);

          return (
            <DateInput
              label="Späteste Rückreise"
              value={state.lastCheckOutDate}
              onChange={actions.setLastCheckoutDate}
              defaultValue={maxDate}
              minDate={state.firstCheckInDate}
              maxDate={maxDate}
              onEnter={() => {
                trackGA4FormInteraction('kreuzfahrtsuche', 'click.last-check-out-date');
              }}
            />
          );
        })()}
        </InputWrap>
        <InputWrap>
          <SelectInput
            label="Reisedauer"
            options={options.cruise.duration}
            onChange={actions.setCruiseDuration}
            value={state.cruiseDuration}
            onEnter={() => {
              trackGA4FormInteraction('kreuzfahrtsuche', 'click.duration');
            }}
          /> <SelectInput
          label="Region"
          options={options.geoLocations.regions}
          onChange={actions.setRegions}
          value={state.regions}
          onEnter={() => {
            trackGA4FormInteraction('kreuzfahrtsuche', 'click.regions');
          }}
        /></InputWrap>
        <InputWrap>
          <SelectInput
            label="Schiff"
            options={options.cruise.ships}
            onChange={actions.setShips}
            value={state.ships}
            onEnter={() => {
              trackGA4FormInteraction('kreuzfahrtsuche', 'click.ships');
            }}
          /> <SelectInput
          label="Kabinentyp"
          options={options.cruise.cabinTypes}
          onChange={actions.setCabinTypes}
          value={state.cabinTypes}
          onEnter={() => {
            trackGA4FormInteraction('kreuzfahrtsuche', 'click.cabin-types');
          }}
        /></InputWrap>
        <InputWrap>
          <SelectInput
            label="Wunschflughafen für Anreise"
            options={options.flight.departureAirports}
            onChange={actions.setDepartureAirport}
            value={state.departureAirports}
            onEnter={() => {
              trackGA4FormInteraction('kreuzfahrtsuche', 'click.departure-airports');
            }}
          />


          <SelectInput
            disabled={state.arrivalLikeDeparture}
            label="Wunschflughafen für Rückreise"
            options={options.flight.departureAirports}
            onChange={actions.setArrivalAirport}
            onEnter={() => {
              trackGA4FormInteraction('kreuzfahrtsuche', 'click.arrival-airports');
            }}
            value={
              state.arrivalLikeDeparture
                ? state.departureAirports
                : state.arrivalAirports
            }
          /><ArrivalLikeDeparture
          value={state.arrivalLikeDeparture}
          onChange={() => {
            trackGA4FormInteraction('kreuzfahrtsuche', 'click.checkbox.arrival-like-departure.' + (!state.arrivalLikeDeparture ? 'true' : 'false'));
            actions.toggleArrivalLikeDeparture();
          }}
        />
        </InputWrap>
      </SearchWrap>

      <Clear />
      <Submit
        state={state}
        onClick={() => {
          const {
            adults,
            children,
            firstCheckInDate,
            lastCheckOutDate,
            cruiseDuration,
            departureAirports,
            arrivalAirports,
            cabinTypes,
            ships,
            regions,
          } = state;

          trackEvents([{
            action: 'Kreuzfahrtsuche_Reisen_Anzeigen',
            label: 'Button_Reisen anzeigen',
          }, {
            action: 'Kreuzfahrtsuche_Erwachsene',
            label: `${adults} Erwachsene`,
          }, {
            action: 'Kreuzfahrtsuche_Kinder',
            label: `${children} Kinder`,
          }, {
            action: 'Kreuzfahrtsuche_Frühester_Reisebeginn',
            label: format(firstCheckInDate, 'dd.MM.yyyy'),
          }, {
            action: 'Kreuzfahrtsuche_Späteste_Rückreise',
            label: format(lastCheckOutDate, 'dd.MM.yyyy'),
          }, {
            action: 'Kreuzfahrtsuche_Reisedauer',
            label: `${cruiseDuration.name} Nächte`,
          }, {
            action: 'Kreuzfahrtsuche_Wunschflughafen_Anreise',
            label: trackingValueList(departureAirports),
          }, {
            action: 'Kreuzfahrtsuche_Wunschflughafen_Abreise',
            label: trackingValueList(arrivalAirports),
          }, {
            action: 'Kreuzfahrtsuche_Kabinentyp',
            label: trackingValueList(cabinTypes),
          }, {
            action: 'Kreuzfahrtsuche_Schiff',
            label: trackingValueList(ships),
          }, {
            action: 'Kreuzfahrtsuche_Region',
            label: trackingValueList(regions),
          }]);

          trackGA4ClickEvent('navi-main.button.reisen-anzeigen');
        }}
      />
      <Reset
        state={state}
        resetState={resetState}
        onClick={() => {
          trackEvent({
            action: 'Kreuzfahrtsuche_Zurücksetzen',
            label: 'Button_Auswahl zurücksetzen',
          });
          trackGA4ClickEvent('navi-main.button.auswahl-zuruecksetzen');
          actions.reset(resetState);
        }}
      />
      {children}
    </>
  );
};

export default Search;
