import { parseISO } from 'date-fns/esm';

import { formatISO } from './formatDate';
import { cabinTypeEnum } from '../api';

export type BaseDetailParams = {
  journeyIdentifier: string;
  tariffCode: string;
  startDate: Date;
  cabinCode: string;
  adults: number;
  children: number;
  cabinType: cabinTypeEnum;
};
type DetailParamsWithFlights = BaseDetailParams & {
  departureAirportCode: string;
  arrivalAirportCode: string;
};

export type DetailParams = BaseDetailParams | DetailParamsWithFlights;

export function isDetailParamsWithFlights(
  params: DetailParams,
): params is DetailParamsWithFlights {
  return Boolean(
    (params as DetailParamsWithFlights).departureAirportCode &&
      (params as DetailParamsWithFlights).arrivalAirportCode,
  );
}

const SEPARATOR = '~';

export function serializeDetailParams(cabinId: DetailParams) {
  if (isDetailParamsWithFlights(cabinId)) {
    const {
      journeyIdentifier,
      tariffCode,
      cabinCode,
      cabinType,
      adults,
      children,
      departureAirportCode,
      arrivalAirportCode,
      startDate,
    } = cabinId;
    return encodeURIComponent(
      [
        journeyIdentifier,
        formatISO(startDate),
        tariffCode,
        cabinType,
        cabinCode,
        adults,
        children,
        departureAirportCode,
        arrivalAirportCode,
      ].join(SEPARATOR),
    );
  }

  const {
    journeyIdentifier,
    tariffCode,
    cabinType,
    cabinCode,
    startDate,
    adults,
    children,
  } = cabinId;
  return encodeURIComponent(
    [
      journeyIdentifier,
      formatISO(startDate),
      tariffCode,
      cabinType,
      cabinCode,
      adults,
      children,
    ].join(SEPARATOR),
  );
}

export function parseDetailParams(cabinId: string): DetailParams {
  const parts = decodeURIComponent(cabinId).split(SEPARATOR);
  if (parts.length === 7) {
    return {
      journeyIdentifier: parts[0],
      startDate: parseISO(parts[1]),
      tariffCode: parts[2],
      cabinType: parts[3] as cabinTypeEnum,
      cabinCode: parts[4],
      adults: parseInt(parts[5], 10),
      children: parseInt(parts[6], 10),
    };
  } else if (parts.length === 9) {
    return {
      journeyIdentifier: parts[0],
      startDate: parseISO(parts[1]),
      tariffCode: parts[2],
      cabinType: parts[3] as cabinTypeEnum,
      cabinCode: parts[4],
      adults: parseInt(parts[5], 10),
      children: parseInt(parts[6], 10),
      departureAirportCode: parts[7],
      arrivalAirportCode: parts[8],
    };
  }
  throw new Error(`Invalid cabinId: "${cabinId}"`);
}
