import * as React from 'react';
import styled from 'styled-components/macro';

import { Cruise as CruiseType } from './Types';
import CruiseTable from './CruiseTable';
import Cruise from './Cruise';

import LoadingRect from '../LoadingRect';
import { Td, Tr } from '../Table';
import { useTariffs, useThrottled } from '../../lib';
import {Option} from '../../types';

type Props = {
  cruises: CruiseType[] | null;
  filteredTariffs?: Option[] | [];
};

const NoResults = styled(Td)`
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  padding-top: 40px;
`;

const CruiseList: React.FC<Props> = React.memo(({ cruises: allCruises, filteredTariffs }) => {
  const tariffs = useTariffs();
  const cruises = useThrottled(allCruises);

  return (
    <CruiseTable
      active={Boolean(!cruises || cruises.length)}
      resultHint={
        allCruises
          ? `${allCruises.length} passende Reise${
              allCruises.length > 1 ? 'n' : ''
            } gefunden`
          : ''
      }
      filteredTariffs={filteredTariffs}
    >
      {cruises ? (
        cruises.length ? (
          cruises.map((cruise) => (
            <Cruise
              key={cruise.journeyIdentifier}
              cruise={cruise}
              filteredTariffs={filteredTariffs}
            />
          ))
        ) : (
          <Tr>
            <NoResults>
              Mit diesen Angaben konnte leider keine passende Reise gefunden
              werden.
            </NoResults>
          </Tr>
        )
      ) : null}
      {(!allCruises || !cruises
        ? [0, 1]
        : cruises.length === allCruises.length
        ? []
        : [0]
      ).map((key) => (
        <Tr key={key}>
          {Array(tariffs.length + 1)
            .fill('')
            .map((_, i) => (
              <Td key={i}>
                <LoadingRect height={28} />
                <LoadingRect sep />
              </Td>
            ))}
        </Tr>
      ))}
    </CruiseTable>
  );
});

export default CruiseList;
