import * as React from 'react';
import styled from 'styled-components/macro';

import { Tr, Td, TitleSecondTd } from '../Table';
import {
  colors,
  useTariffs,
  CRUISES_ROUTE,
  serializeDetailParams,
  useSearchParameters,
  MISSING_FLIGHT_INFO,
} from '../../lib';

import CruiseLink from '../FixedSearchLink';
import { Cabin, isCabinWithoutFlight } from './Types';
import CabinPrice from './CabinPrice';
import { CATEGORY_ERROR, trackEvent } from '../../tealium';

type Props = {
  cabins: Cabin[];
  startDate: Date;
  journeyIdentifier: string;
};
const Title = styled.span`
  display: block;
  height: 28px;
  font-size: 22px;
  margin-bottom: 6px;
`;

const StyledTr = styled(Tr)`
  background-color: ${colors.lightBlue};
`;
const StyledLink = styled(CruiseLink)`
  text-decoration: none;
  outline: none;
  &:focus,
  &:hover {
    background-color: ${colors.slightlyLessLightLightBlue};
  }
`;

const CabinRow: React.FC<Props> = React.memo(
  ({ cabins, journeyIdentifier, startDate }) => {
    const tariffs = useTariffs();
    const parameters = useSearchParameters();

    if (!parameters) {
      trackEvent({
        category: CATEGORY_ERROR,
        action: 'Navigation',
        label: 'Missing search Parameters'
      });
      throw new Error('Missing search Parameters');
    }

    return (
      <StyledTr data-testid="row">
        <Td>
          <Title as={TitleSecondTd}>{cabins[0].name}</Title>
        </Td>
        {tariffs.map(({ code }) => {
          const cabin = cabins.find(({ tariffCode: t }) => t === code);
          if (!cabin) {
            return <Td key={code} />;
          }

          return (
            /* No Idea how to correctly type props of aliased styled components */
            <Td
              right
              key={code}
              {...({
                as: StyledLink,
                to: `${CRUISES_ROUTE}/${serializeDetailParams({
                  cabinCode: cabin.code,
                  cabinType: cabin.type,
                  startDate,
                  tariffCode: cabin.tariffCode,
                  journeyIdentifier,
                  adults: parameters.passenger.adults,
                  children: parameters.passenger.children,
                  ...(isCabinWithoutFlight(cabin)
                    ? {}
                    : {
                        departureAirportCode: cabin.pricesWithFlight[0].departureAirport.code || MISSING_FLIGHT_INFO ,
                        arrivalAirportCode: cabin.pricesWithFlight[0].arrivalAirport.code || MISSING_FLIGHT_INFO,
                      }),
                })}`,
              } as any)}
            >
              <CabinPrice cabin={cabin} fixedHeight={true} />
            </Td>
          );
        })}
      </StyledTr>
    );
  },
);

export default CabinRow;
