import * as React from 'react';
import styled from 'styled-components/macro';
import createPdf from './CruiseList/createPdf';
import { SpinnerSmallBlue } from './Spinner';
import { Download, colors, useBaseData } from '../lib';
import { ga4formatString, trackEvent, trackGA4ClickEvent } from '../tealium';

const Wrapper = styled.div`
  margin: 80px 0 0 0;
  width: 278px;
  display: inline-block;
  position: relative;
`;

const StyledButton = styled.button`
  font-size: 18px;
  color: ${colors.blue};

  &:hover {
    & {
      color: ${colors.grayBlue};
    }
  }

  svg {
    position: relative;
    top: 4px;
  }

  span {
    padding-left: 10px;
  }
`;

const Spinner = styled(SpinnerSmallBlue)`
  position: absolute;
  right: 0;
  left: auto;
  top: 7px;
`;

const ExportPDFBaseData: React.FC = () => {
  const baseData = useBaseData();
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <Wrapper>
      <StyledButton
        onClick={() => {
          trackEvent({
            action: 'Kreuzfahrtsuche_Download_Tabelle',
            label: 'Downloadlink_PLUS- und PUR-Tarif Tabelle',
          });
          trackGA4ClickEvent('footer.link.' + ga4formatString('PLUS- und PUR-Tarif Tabelle'));
          setLoading(true);
          createPdf(baseData, () => {
            setLoading(false);
          });
        }}
      >
        <Download tabIndex={-1} focusable="false" />
        <span>PLUS- und PUR-Tarif Tabelle</span>
      </StyledButton>
      {loading && <Spinner />}
    </Wrapper>
  );
};

export default ExportPDFBaseData;
